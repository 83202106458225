import { current } from '@reduxjs/toolkit';
import { IDecodedResponse } from 'features/user/userSlice';
import { Parser } from 'html-to-react';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import React, { Dispatch, Key, ReactElement, SetStateAction } from 'react';
import { v4 as uuidv4 } from 'uuid';

export function isMacOs(): boolean {
  return /Mac/.test(navigator.userAgent);
}

export function getUserIdFromToken(): number | null {
  const token = Cookies.get('token');
  if (token) {
    const decodedResponse: IDecodedResponse = token ? jwt_decode(token) : {};
    const uId = decodedResponse?.data?.user?.id;
    return uId ?? null;
  }
  return null;
}

export async function getHtml(slug: string, locale: string): Promise<string> {
  try {
    const loc = `/html/${slug}-${locale}.html`;
    const res = await fetch(loc);
    const data = await res.text();
    if (!data.toLowerCase().startsWith('<!doctype')) return data;
    else
      return '<div>Error loading page.</div><div>Invalid content fetched.</div>';
  } catch (err) {
    console.error(err);
    return '<div>Error loading page.</div><div>Invalid content fetched.</div>';
  }
}

export function renderHtml(content: string) {
  const htmlToReactParser = new Parser();
  const elements = htmlToReactParser.parse(content);
  if (!elements) return null;
  if (Array.isArray(elements))
    return elements.map((element: ReactElement) => {
      if (typeof element === 'string' && element === '\n') return null;
      return element;
    });
  return elements;
}

export function escapeRegEx(str: string) {
  /* eslint-disable no-useless-escape */
  return str.replace(/[\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
  /* eslint-enable no-useless-escape */
}

export function evaluateThis(condition: any, ...args: any[]) {
  if (typeof condition === 'function') return condition(...args);
  return condition;
}

export function findFirstSiblingWithClass(
  element: HTMLElement | null,
  className: string
): HTMLElement | null {
  if (!element) return null;
  const parent = element.parentNode;
  if (!parent) return null;
  return parent?.querySelector(`.${className}`);
}

// clones a given array of React elements and adds a unique key property
// to its items or modifies it to be unique if they already have one
export function addKeyProp(elements: JSX.Element[]): JSX.Element[] {
  const keys: Key[] = [];
  return elements.map((element, index) => {
    let key = element.key !== null ? element.key : index;
    while (keys.includes(key)) {
      key += 'a';
    }
    keys.push(key);
    return React.cloneElement(element, { key: key });
  });
}

// returns a function that delays processing,
// can be used to avoid overactive event listeners
export function debounce(func: () => void, delay: number) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function debouncedFunction() {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(func, delay);
  };
}

export function fileSize(size?: number): string {
  if (!size) return '?? KB';
  const f = size < 2_000 ? 1 : size < 450_000 ? 1000 : 1000_000;
  const u = size < 2_000 ? 'B' : size < 450_000 ? 'KB' : 'MB';
  return `${(size / f).toFixed(f > 1000 ? 1 : 0)} ${u}`;
}

export function hideEmail(email: string): string {
  if (!email) return email;
  const [before, after] = email.split('@');
  return (
    before.substring(0, 3) +
    '...@' +
    after.substring(0, 2) +
    '...' +
    after.substring(after.length - 2)
  );
}

export function parentClassListContains(
  element: HTMLElement,
  classname: string
): boolean {
  let el: HTMLElement | null = element;
  while (el) {
    if (el.classList.contains(classname)) return true;
    el = el.parentElement;
  }
  return false;
}

export function getGaugeStyle(
  percentage: number,
  forPdf: boolean = false
): React.CSSProperties {
  const normalizedPercentage = Math.max(0, Math.min(100, percentage));
  const width = `${normalizedPercentage}%`;
  return forPdf
    ? {
        width,
      }
    : {
        width,
        transition: 'width 0.5s ease',
      };
}

// truncate a string to maxlen and add ellipsis if needed
export function truncateTo(
  input: string,
  maxlen: number,
  noEllipsis: boolean = false
): string {
  return input && input.length > maxlen
    ? input.substring(0, maxlen) + (noEllipsis ? '' : '…')
    : input;
}

export function toLowerCase(s: string): string {
  return s.toLowerCase();
}

// console log that works for proxy's too
export function proxyLog(label: string, state: any) {
  try {
    console.log(`proxy: current(${label}):`, current(state));
  } catch (e) {
    console.log(`${label}:`, state);
  }
}

export function getCurrent(state: any): any {
  try {
    return current(state);
  } catch (e) {
    return state;
  }
}

// replaces whitespace with comma's, while avoiding double comma's and whitespace at start and end
export function makeCSV(inputString: string): string {
  return inputString.trim().replace(/\s+/g, ',').replace(/,{2,}/g, ',');
}

export function withoutSeconds(date?: Date | null): Date | null | undefined {
  if (date) {
    const d = new Date(date);
    d.setSeconds(0, 0);
    return d;
  }
  return date;
}

export function generateRandomArray(
  size: number,
  lower: number,
  upper: number,
  seed: number
): number[] {
  let count = seed === 0 ? 1 : 0;
  const customRandom = (): number => {
    const x = Math.sin(seed + count++) * 10000;
    return Math.floor((x - Math.floor(x)) * (upper - lower + 1)) + lower;
    // return Math.floor(x * (upper - lower + 1)) + lower;
  };
  const randomArray: number[] = Array.from({ length: size }, () =>
    customRandom()
  );
  return randomArray;
}

export function inScreenPortion(
  element?: HTMLElement | null,
  portion?: number
  // if (0 < portion < 1) considered factor, otherwise considered pixels
  // if negative considered from bottom
): boolean {
  if (!element) return true; // assume true of no element given
  const rect = element.getBoundingClientRect();
  const viewportHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const fromBottom = portion ? portion < 0 : false;
  const absPortion = portion ? Math.abs(portion) : undefined;
  const included = absPortion
    ? absPortion < 1
      ? viewportHeight * absPortion
      : absPortion
    : viewportHeight * 0.5;
  return fromBottom
    ? rect.top > viewportHeight - included
    : rect.top < included;
}

export function generateUuidCode() {
  return uuidv4();
}

export function getLink(path: string): string {
  return `${window.location.protocol}//${window.location.host}/${path}`;
}

export function copyLink(
  path: string,
  setCopied?: Dispatch<SetStateAction<boolean>>
) {
  navigator.clipboard.writeText(getLink(path));
  if (setCopied) setCopied(true);
  setTimeout(() => {
    if (setCopied) setCopied(false);
  }, 3000);
}

export function setCharAt(str: string, index: number, chr: string) {
  if (index > str.length - 1) return str;
  return str.substring(0, index) + chr + str.substring(index + 1);
}

export function convertToRoman(num: number) {
  const roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };
  let str = '';
  for (let i of Object.keys(roman)) {
    const q = Math.floor(num / roman[i as keyof typeof roman]);
    num -= q * roman[i as keyof typeof roman];
    str += i.repeat(q);
  }
  return str;
}

export function pathStartsWith(slug: string | string[]): boolean {
  if (Array.isArray(slug)) {
    return slug.some((s) => window.location.pathname.startsWith('/' + s));
  } else {
    return window.location.pathname.startsWith('/' + slug);
  }
}
