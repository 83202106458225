/* CSS */
import 'bootstrap/dist/css/bootstrap.min.css';
import 'css/styles.scss';

/* React & libraries */
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import {
  Params,
  RouterProvider,
  createBrowserRouter,
  redirect,
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

/* Helpers */
import ConnectedIntlProvider from 'app/connectedIntlProvider';
import { persistor, store } from 'app/store';
import { SLUGS } from 'helpers/consts';
import { getUserIdFromToken } from 'helpers/helpers';

/* Components */
import DialogueView from 'components/dialogues/DialogueView';
import Layout from 'components/layout/Layout';
import Error404 from 'components/navigation/Error404';
import ProjectOverview from 'components/projects/ProjectOverview';
// import SortableContextProvider from 'components/sortables/SortableContext';
import StaticPage from 'components/static/StaticPage';
import SetLocale from 'components/user/SetLocale';

/* Test components */
import Test from 'test/Test';

/* AsyncAPI */
import { AsyncAPI } from 'app/AsyncAPI/AsyncAPI';
import TryOut from 'components/user/TryOut';

function addRoute(
  routes: Object[],
  slug: string | string[],
  params: string | null,
  route: Object
): number {
  const slugs = Array.isArray(slug) ? slug : [slug];
  slugs.forEach((s) => {
    // console.log('slug:', s + (params ?? ''));
    routes.push({ ...route, path: '/' + s + (params ?? '') });
  });
  return routes.length;
}

function AppRouter() {
  const routes: Object[] = [];
  addRoute(routes, '/', null, {
    element: (
      <Layout white>
        <StaticPage />
      </Layout>
    ),
    loader: async ({
      request,
      params,
    }: {
      request?: Request;
      params?: Params;
    }) => {
      return redirect(
        `/${getUserIdFromToken() ? SLUGS.MYPROJECTS : SLUGS.WELCOME}`
      );
    },
  });
  addRoute(routes, '/en', null, {
    element: (
      <Layout white>
        <SetLocale locale="en" />
      </Layout>
    ),
  });
  addRoute(routes, SLUGS.WELCOME, null, {
    element: (
      <Layout white>
        <StaticPage />
      </Layout>
    ),
  });
  addRoute(routes, SLUGS.TRYOUT, null, {
    element: (
      // <Layout restricted fixed>
      <Layout fixed>
        <TryOut />
      </Layout>
    ),
  });
  addRoute(routes, SLUGS.TEST, null, {
    element: (
      <Layout white>
        <Test />
      </Layout>
    ),
  });
  addRoute(routes, SLUGS.MYPROJECTS, null, {
    element: (
      <Layout white restricted>
        <ProjectOverview />
      </Layout>
    ),
  });
  addRoute(routes, SLUGS.LOGIN, '/:code?', {
    element: (
      <Layout white>
        <StaticPage />
      </Layout>
    ),
  });
  addRoute(routes, SLUGS.SIGNUP, '/:code?', {
    element: (
      <Layout white>
        <StaticPage />
      </Layout>
    ),
  });
  addRoute(routes, SLUGS.JOIN, '/:code?', {
    element: (
      <Layout white>
        <StaticPage />
      </Layout>
    ),
  });
  addRoute(routes, SLUGS.RESETPWD, '/:token', {
    element: (
      <Layout white>
        <StaticPage />
      </Layout>
    ),
  });
  addRoute(routes, SLUGS.VERIFYMAIL, '/:token/:regcode?', {
    element: (
      <Layout white>
        <StaticPage />
      </Layout>
    ),
  });
  addRoute(routes, SLUGS.GUEST, '/:code?', {
    element: (
      <Layout white>
        <StaticPage />
      </Layout>
    ),
  });
  addRoute(routes, SLUGS.ABOUT, null, {
    element: (
      <Layout white>
        <StaticPage slug={SLUGS.ABOUT} className="help_page" />
      </Layout>
    ),
  });
  addRoute(routes, SLUGS.CONSENT, null, {
    element: (
      <Layout white>
        <StaticPage slug={SLUGS.CONSENT} className="help_page" />
      </Layout>
    ),
  });
  addRoute(routes, SLUGS.HELP, null, {
    element: (
      <Layout white>
        <StaticPage slug={SLUGS.HELP} className="help_page" />
      </Layout>
    ),
  });
  addRoute(routes, SLUGS.HELP + '/' + SLUGS.TOOLS, null, {
    element: (
      <Layout white>
        <StaticPage slug={SLUGS.TOOLS} className="help_page" />
      </Layout>
    ),
  });
  addRoute(routes, SLUGS.PRIVACY, null, {
    element: (
      <Layout white>
        <StaticPage slug={SLUGS.PRIVACY} className="help_page" />
      </Layout>
    ),
  });
  addRoute(routes, SLUGS.DIALOGUE, '/:dialogueId', {
    element: (
      <Layout restricted fixed>
        <DialogueView />
      </Layout>
    ),
  });
  // {
  //   path: `/:code?`,
  //   element: (
  //     <Layout white>
  //       <StaticPage />
  //     </Layout>
  //   ),
  //   loader: async ({
  //     request,
  //     params,
  //   }: {
  //     request?: Request;
  //     params?: Params;
  //   }) => {
  //     if (params) {
  //       console.log('params[code]:', params['code']);
  //       return redirect(`/${SLUGS.GUEST[0]}/${params['code']}`);
  //     }
  //   },
  // },
  addRoute(routes, '*', null, {
    element: (
      <Layout white>
        <Error404 />
      </Layout>
    ),
  });

  console.log('routes:', routes);
  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
}

export default function App() {
  useEffect(() => {
    const token = Cookies.get('token');

    if (token == null) return;

    AsyncAPI.connect();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedIntlProvider>
          {/* <SortableContextProvider> */}
          <div id="App">
            {/* <RouterProvider router={router} /> */}
            <AppRouter />
          </div>
          {/* </SortableContextProvider> */}
        </ConnectedIntlProvider>
      </PersistGate>
    </Provider>
  );
}
