import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState, store } from 'app/store';
import { BlockType, ListType } from 'features/block/blockSlice';
import { GUEST_TRYOUT_ACCESS_CODE } from 'helpers/consts';
import {
  IAppSettings,
  NotInitial,
  fetchAppSettings,
  patchAppSettingsData,
  postAppSettingsData,
} from './appSettingsAPI';

// type ToolSettings = {
//   blocks: BlockType[];
//   lists: ListType[];
// };
export const BlockTypeValues = Object.values(BlockType) as string[];
export const ListTypeValues = Object.values(ListType) as string[];
export const emptySettings: IAppSettings = {
  id: 0,
  tools: {
    blocks: [],
    lists: [],
  },
  rights: {
    userCreateProject: false,
    guestTryout: false,
    tryoutDialogue: 0,
    tryoutCode: GUEST_TRYOUT_ACCESS_CODE,
  },
  maxmbs: {
    // img: 10_000_000,
    // doc: 20_000_000,
    // xls: 20_000_000,
    // ppt: 50_000_000,
    // pdf: 50_000_000,
  },
};

export interface IAppSettingsState {
  settings: IAppSettings;
  connectionError: number;
  status: 'idle' | 'loading' | 'failed';
  errors: any;
}

export type AppSettingsStateProps = {
  settings: IAppSettings;
  connectionError: number;
};

export function mapAppSettingsStateToProps(
  state: RootState
): AppSettingsStateProps {
  return {
    settings: state.appSettings.settings,
    connectionError: state.appSettings.connectionError,
  };
}

export function maxUploadSizeAllowed(type: string): number {
  if (!type) return -1;
  console.log('type:', type);
  const settings = store.getState().appSettings.settings;
  if (settings && settings.maxmbs) {
    console.log('settings.maxmbs:', settings.maxmbs);
    const s = settings.maxmbs[type.slice(0, 3)];
    if (typeof s === 'number') return s * 1_000_000;
  }
  return -1;
}

export function isToolAvailable(key?: string): boolean {
  if (!key) return false;
  const settings = store.getState().appSettings.settings;
  if (settings) {
    if (settings.tools.blocks?.includes(key)) return true;
    if (settings.tools.lists?.includes(key)) return true;
  }
  return false;
}

export function isRightGranted(key?: string): boolean {
  if (!key) return false;
  const settings = store.getState().appSettings.settings;
  if (settings && settings.rights) return settings.rights[key] ?? false;
  return false;
}

export const initialState: IAppSettingsState = {
  settings: { ...emptySettings, _initial: true },
  connectionError: 0,
  status: 'idle',
  errors: '',
};

export const fetchAppSettingsAsync: any = createAsyncThunk(
  'appsettings/fetchAppSettingsAsync',
  async (id: number, thunkAPI) => {
    try {
      const res = { response: await fetchAppSettings() };
      if (res.response.data.length === 0) {
        thunkAPI.dispatch(postAppSettingsAsync(initialState.settings));
      }
      return res;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const postAppSettingsAsync = createAsyncThunk(
  'appsettings/postAppSettingsData',
  async (data: IAppSettings, thunkAPI) => {
    try {
      return {
        response: await postAppSettingsData(NotInitial(data)),
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const patchAppSettingsAsync = createAsyncThunk(
  'appsettings/patchAppSettingsData',
  async (
    {
      data,
    }: {
      data: IAppSettings;
    },
    thunkAPI
  ) => {
    try {
      return {
        response: await patchAppSettingsData(NotInitial(data)),
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    setConnectionError: (state, action) => {
      state.connectionError = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAppSettingsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAppSettingsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        if (action.payload.response.data?.length > 0) {
          state.settings = NotInitial(
            action.payload.response.data[0]
          ) as IAppSettings;
          if (!state.settings.maxmbs) state.settings.maxmbs = {};
        }
      })
      .addCase(fetchAppSettingsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      })
      .addCase(postAppSettingsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postAppSettingsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.settings = action.meta.arg;
      })
      .addCase(postAppSettingsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      })
      .addCase(patchAppSettingsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchAppSettingsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        // proxyLog('state:', state);
        state.settings = action.meta.arg.data;
      })
      .addCase(patchAppSettingsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      });
  },
});

export const appSettingsActions = appSettingsSlice.actions;
export const { setConnectionError } = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
